<template>
  <div class="admin-unhealthy-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="icon-button fa fa-download" v-b-tooltip title="Download CSV" @click="exportCSV"></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Unhealthy Devices</h2></b-card-header>
          <b-card-body>
            <div>
              <label for="health-threshold">Health Threshold</label>
              <b-form-input id="health-threshold" v-model="healthThreshold" type="range" min="0" max="30" step="1" debounce="300" @change="thresholdChange"></b-form-input>
              <div class="mt-2 time-toolbar">
                <b-select :options="timeUnitOptions" v-model="timeUnits" class="time-units-input" @change="thresholdChange"></b-select>
                without Data: {{ healthThreshold }}</div>
            </div>
            <admin-device-list v-on:change="selectChange" ref="adminDeviceList" :devices="deviceList" hide-advanced :show-fields="deviceFields"></admin-device-list>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AdminDeviceList from '../../components/admin/AdminDeviceList'
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'
import moment from 'moment'

export default {
  name: 'admin-unhealthy-devices',
  components: {
    AdminDeviceList
  },
  data: function () {
    return {
      timeUnits: 'days',
      timeUnitOptions: ['days', 'hours', 'minutes'],
      healthThreshold: 2,
      selectedDevices: [],
      deviceList: [],
      deviceFields: ['selected', 'imei', 'device_code', 'name', 'device_phone', 'location.datetime', 'actions']
    }
  },
  created () {
    this.loadDeviceList()
  },
  methods: {
    loadDeviceList: async function () {
      let response = await DataProvider.getAdminDeviceList(undefined, {})
      if (response.success) {
        let threshold = moment(new Date()).subtract(this.healthThreshold, this.timeUnits)
        console.log('Device Data: ', response.data)
        this.deviceList = response.data.filter(x => {
          if (x.location) {
            return moment(x.location.datetime).isBefore(threshold)
          } else {
            return true
          }
        })
        console.log(this.deviceList)
      } else {
        ErrorHelper.displayDataErrorToast(response)
      }
    },
    thresholdChange: function () {
      this.loadDeviceList()
    },
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    refreshList: function () {
      this.$refs.adminDeviceList.loadData()
    },
    selectChange: function (newSelection) {
      this.selectedDevices = newSelection
    },
    addDevice: async function () {
      console.log('create')
    },
    exportCSV: async function () {
      // PapaParse Configuration
      let config = {
        quotes: false, // or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        newline: '\r\n',
        skipEmptyLines: false, // or 'greedy',
        columns: null // or array of strings
      }
      // Download User Data
      let userDataQuery = await DataProvider.adminGetUserList()
      if (!userDataQuery.success) {
        ErrorHelper.displayDataErrorToast(userDataQuery)
        return
      }
      let userData = userDataQuery.data
      let exportData = []
      // To avoid contaminating the source data we need to create new objects to export, since we will be
      // applying formatting to the values to make them human-readable.
      let data = this.deviceList
      for (let x in data) {
        let row = {
          imei: data[x].device_imei,
          device_code: data[x].device_code,
          device_phone: data[x].device_phone,
          device_name: data[x].device_name
        }
        // Check if we have location data to get a timestamp from
        if (data[x].location) {
          row.last_report = data[x].location.datetime
        } else {
          row.last_report = 'N/A'
        }
        // Match the User Id and include the user details
        if (data[x].hasOwnProperty('user_id') && data[x].user_id) {
          // NOTE: The 'id' field on Users is a string, but a number on devices...
          let user = userData.find(item => item.id.toString() === data[x].user_id.toString())
          if (user) {
            row.owner_name = user.name
            row.owner_email = user.email
            row.owner_phone = user.mobile
          } else {
            // This shouldn't happen... if the device is assigned the User ID should exist.
            row.owner_name = 'Owner Not Found!'
          }
        } else {
          row.owner_name = 'Unassigned'
        }
        exportData.push(row)
      }
      let csvData = this.$papa.unparse(exportData, config)
      this.$papa.download(csvData, 'Unhealthy Devices')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-unhealthy-devices {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 3rem;
  }

  .icon-composite {
    display: contents;
  }

  .icon-composite-main {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
    background-color: transparent;
    color: $theme-color-primary-5;
    text-align: center;
    cursor: pointer;
  }

  .icon-composite-child {
    position: relative;
    font-size: 1.8rem;
    left: -1.8rem;
    top: -1.2rem;
    background-color: transparent;
    color: $theme-color-primary-2;
    text-align: center;
    cursor: pointer;
  }

  .icon-composite:focus, .icon-composite:hover .icon-composite-highlight {
    color: $theme-color-primary-1!important;
    //border-color: $theme-color-primary-3;
    //background: $theme-color-secondary-2;
    text-shadow: 0px 0px 20px $theme-color-secondary-2, 0px 0px 10px $theme-color-secondary-2;
  }

  .time-toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .time-units-input {
    display: flex;
    width: 10rem;
    margin: 5px 10px;
  }

</style>
