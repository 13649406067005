var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "admin-unhealthy-devices" }, [
    _c("div", { staticClass: "toolbar justify-content-between" }, [
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button",
          class: _vm.$config.icons.general.back,
          attrs: { title: "Back to Admin" },
          on: { click: _vm.clickBack }
        })
      ]),
      _c("div", [
        _c("i", {
          directives: [{ name: "b-tooltip", rawName: "v-b-tooltip" }],
          staticClass: "icon-button fa fa-download",
          attrs: { title: "Download CSV" },
          on: { click: _vm.exportCSV }
        })
      ])
    ]),
    _c(
      "div",
      { staticClass: "list-containter" },
      [
        _c(
          "b-card",
          [
            _c("b-card-header", [_c("h2", [_vm._v("Unhealthy Devices")])]),
            _c(
              "b-card-body",
              [
                _c(
                  "div",
                  [
                    _c("label", { attrs: { for: "health-threshold" } }, [
                      _vm._v("Health Threshold")
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        id: "health-threshold",
                        type: "range",
                        min: "0",
                        max: "30",
                        step: "1",
                        debounce: "300"
                      },
                      on: { change: _vm.thresholdChange },
                      model: {
                        value: _vm.healthThreshold,
                        callback: function($$v) {
                          _vm.healthThreshold = $$v
                        },
                        expression: "healthThreshold"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "mt-2 time-toolbar" },
                      [
                        _c("b-select", {
                          staticClass: "time-units-input",
                          attrs: { options: _vm.timeUnitOptions },
                          on: { change: _vm.thresholdChange },
                          model: {
                            value: _vm.timeUnits,
                            callback: function($$v) {
                              _vm.timeUnits = $$v
                            },
                            expression: "timeUnits"
                          }
                        }),
                        _vm._v(" without Data: " + _vm._s(_vm.healthThreshold))
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("admin-device-list", {
                  ref: "adminDeviceList",
                  attrs: {
                    devices: _vm.deviceList,
                    "hide-advanced": "",
                    "show-fields": _vm.deviceFields
                  },
                  on: { change: _vm.selectChange }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }